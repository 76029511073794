var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reset-password"}},[_c('SfHeading',{staticClass:"heading sf-heading--no-underline",attrs:{"level":3,"title":_vm.$t('Reset Password')}}),_vm._v(" "),(!_vm.isPasswordChanged)?_c('div',[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setNewPassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"error-message":_vm.$t(errors[0]),"valid":!errors[0],"label":"Your email","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('reset-password-modal-password'),expression:"'reset-password-modal-password'"}],staticClass:"form__element",attrs:{"error-message":_vm.$t(errors[0]),"label":_vm.$t('Password'),"valid":!errors[0],"name":"password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('reset-password-modal-password-repeat'),expression:"'reset-password-modal-password-repeat'"}],staticClass:"form__element",attrs:{"error-message":_vm.$t(errors[0]),"label":_vm.$t('Repeat Password'),"valid":!errors[0],"name":"repeat-password","type":"password"},model:{value:(_vm.form.repeatPassword),callback:function ($$v) {_vm.$set(_vm.form, "repeatPassword", $$v)},expression:"form.repeatPassword"}})]}}],null,true)}),_vm._v(" "),(_vm.passwordMatchError || _vm.forgotPasswordError.setNew)?_c('div',[_vm._v("\n          "+_vm._s(_vm.passwordMatchError || _vm.forgotPasswordError.setNew.message)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('reset-password-modal-submit'),expression:"'reset-password-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"disabled":_vm.forgotPasswordLoading,"type":"submit"}},[_c('SfLoader',{class:{ loader: _vm.forgotPasswordLoading },attrs:{"loading":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Save Password')))])])],1)],1)]}}],null,false,2058837417)})],1):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Password Changed')))]),_vm._v(" "),_c('SfButton',{staticClass:"sf-button",attrs:{"link":"/"}},[_vm._v("\n      "+_vm._s(_vm.$t('Back to home'))+"\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }