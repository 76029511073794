



























































































import { SfButton, SfHeading, SfInput, SfLoader, } from '@storefront-ui/vue';
import { computed, defineComponent, ref, useContext, useRoute, } from '@nuxtjs/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { forgotPasswordGetters } from '~/modules/customer/getters/forgotPasswordGetters';
import { useForgotPassword } from '~/composables';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'ResetPassword',
  components: {
    SfButton,
    SfHeading,
    SfLoader,
    SfInput,
    ValidationProvider,
    ValidationObserver,
  },
  // eslint-disable-next-line consistent-return
  middleware({ redirect, route }) {
    if (!route.query.token) {
      return redirect('/');
    }
  },
  setup() {
    const {
      result,
      setNew,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();
    const passwordMatchError = ref<string | null>(null);

    const form = ref({
      email: '',
      password: '',
      repeatPassword: '',
    });
    const isPasswordChanged = computed(() => forgotPasswordGetters.isPasswordChanged(result.value));

    const route = useRoute();
    const { token } = route.value.query;
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const setNewPassword = async () => {
      passwordMatchError.value = null;
      if (form.value.password !== form.value.repeatPassword) {
        passwordMatchError.value = 'Passwords do not match';
        return;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.getResponse();

        await setNew({
          tokenValue: token as string,
          newPassword: form.value.password,
          email: form.value.email,
          recaptchaToken,
        });
      } else {
        await setNew({
          tokenValue: token as string,
          newPassword: form.value.password,
          email: form.value.email,
        });
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    return {
      isPasswordChanged,
      form,
      setNewPassword,
      forgotPasswordLoading,
      forgotPasswordError,
      passwordMatchError,
      token,
      result,
      isRecaptchaEnabled,
    };
  },
});
